/* css font family add like a Lucida Calligraphy W01 */
@import url(https://db.onlinewebfonts.com/c/efbd8f0d869bf61fbe0f139a1602cda8?family=Lucida+Calligraphy+W01);
/*  Avenir Medium */

/* --------------------- custom scrollbar css ---------------------- */
/* width */

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #424d7917;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #424d7930;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #424d793a;
}

/* --------------------------------------  common component css --------------- */


.three-dot-text {
  display: block;
  width: 100px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.three-dot-text-title {
  display: block;
  width: 140px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.common-table-row {
  border: 1px solid #80808036;
}

.common-table-row tbody tr td {
  text-transform: none !important;
  padding-left: 0px !important;
  /* padding-top: 10px !important;
    padding-bottom: 10px !important; */
}

.row-pagination-last-child .common-table-row thead tr th:last-child {
  text-align: start !important;
}

.common-table-row thead tr th:last-child {
  text-align: center !important;
}

.common-table-row tbody tr td:last-child {
  text-align: center !important;
}


.common-table-row tbody tr td button {
  padding-left: 2px !important;
  padding-right: 5px !important;
}

.common-table-row tbody tr td button:hover {
  background-color: transparent !important;
}

.common-table-row tbody tr td:first-child {
  text-transform: none;
  padding-left: 20px !important;
  /* padding-top: 10px !important; */
  /* padding-bottom: 10px !important; */
}

.common-table-row thead tr th {
  text-transform: none !important;
  padding-left: 0px !important;
}

.common-table-row thead tr th:first-child {
  text-transform: none !important;
  padding-left: 20px !important;
}

.common-table-row thead tr {
  background: #424d7924 !important;
}


.common-table-row thead tr th {
  font-weight: 500 !important;
  font-size: 14px !important;
  text-align: start !important;
  vertical-align: middle !important;
}

.common-table-row tbody tr td {
  padding: 4px 0px 4px 0px !important;
  text-align: start !important;
  vertical-align: middle !important;
}

/* 
.css-1o83ncy-MuiTableCell-root {
  text-align: start;
  vertical-align: middle;
} */

.main-modal-fix-tabel thead {
  position: sticky;
  top: 0px;
  z-index: 999;
}

.text-error {
  /* font-size: 12px; */
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ff3d57;
}

.label-class {
  font-size: 14px;
  color: #5f5d5d;
}

.css-1nmdiq5-menu {
  z-index: 999 !important;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: none !important;
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  border-bottom: 1px solid #8080803b !important;
  margin-bottom: 20px !important;
  font-size: 20px !important;
  padding: 14px 0px 12px 30px !important;
  background: #424d7916 !important;
  color: #222a44 !important;
}

.css-o34k1g-MuiPaper-root-MuiDialog-paper {
  border-radius: 0px !important;
}

.css-rgpb1g-MuiDialogActions-root {
  border-top: 1px solid #8080802c !important;
  padding-top: 18px;
}

.text-input-top {
  margin-top: 15px !important;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding-left: 2px;
  padding-right: 5px;
}

.css-12r29k6-MuiSlider-markLabel {
  /* color: rgb(58 50 115 / 97%) !important; */
  font-size: 13px !important;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: transparent !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.span-permision {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.common-width-apply-th-new {
  width: 250px !important;
}

.common-width-apply-th {
  width: 235px !important;
}

.thead-second-width-address {
  width: 110px !important;
}

.thead-second-width-address-100 {
  width: 100px !important;
}

.thead-second-width {
  width: 150px !important;
}

.thead-second-width-stock-numbers {
  width: 160px !important;
}

.thead-second-width-stock-numbers-refundAmount {
  width: 120px !important;
  text-align: center !important;
}

.thead-second-width-order-date {
  width: 180px !important;
}

.thead-second-width-stone {
  width: 120px !important;
}

.thead-second-width-stone-order {
  width: 150px !important;
  text-align: center !important;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.thead-second-width-stone.css-1o83ncy-MuiTableCell-root {
  text-align: center;
}

.thead-second-width-stock-no {
  width: 200px !important;
}

.thead-second-width-image {
  width: 135px !important;
}

.thead-second-width-stock-no-order-date {
  width: 210px !important;
}

.thead-second-width-action {
  width: 65px !important;
}

.thead-second-width-action-carat {
  width: 60px !important;
}

.thead-second-width-action-50 {
  width: 50px !important;
}

.thead-second-width-action-carat-40 {
  width: 40px !important;
}

/* 
@media (max-width:1440px) {
  .thead-second-width-action-carat {
    width: 65px !important;
  }
} */

.thead-second-width-action-35 {
  width: 35px !important;
}

.thead-second-width-discount {
  width: 85px !important;
}

.thead-second-width-discount-85 {
  width: 85px !important;
}

.thead-second-width-discount-85-align-center {
  width: 85px !important;
  text-align: start !important;
}

.thead-second-width-action-index {
  width: 90px !important;
}

.thead-second-width-action-index-95 {
  width: 95px !important;
}

.three-dot-text-title-stock-no {
  display: block;
  width: 190px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

/* @media (max-width:1440px) {
  .thead-second-width-title-res {
    display: block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .thead-second-width-title-responsive {
    width: 380px !important;
  }

  .thead-second-width-stock-no-res {
    width: 100px !important;
  }
} */

/* @media (max-width:1440px) {
  .thead-second-width-stock-no-res {
    width: 100px !important;
  }
} */

.thead-second-width-title {
  width: 400px !important;
}

.thead-second-width-title2 {
  width: 390px !important;
}

.icon_button {
  padding-left: 2px !important;
  padding-right: 5px !important;
}

.thead-second-width-title-email {
  width: 300px !important;
}

.thead-second-width-title-answer {
  width: 350px !important;
}

.thead-second-width-title-blog {
  width: 700px !important;
}

.thead-second-width-title-780 {
  width: 780px !important;
}

.thead-second-width-title-750 {
  width: 700px !important;
}

.thead-second-width-title-option {
  width: 600px !important;
}

.thead-second-width-title-650 {
  width: 650px !important;
}

.thead-second-width-title-price {
  width: 70px !important;
}

.thead-second-width-action-index-responsive {
  width: 110px !important;
}

.thead-second-width-title-price-78 {
  width: 78px !important;
}

/* responsive css in size 1440 */


.thead-second-width-stone_res {
  width: 215px !important;
}





.thead-second-width-title-subcategory {
  width: 500px !important;
}

.common-thead-second-width-title-description {
  display: block;
  width: 145px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.common-thead-second-width-title-subcategory {
  display: block;
  width: 495px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.common-thead-second-width-title-option {
  display: block;
  width: 595px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.common-thead-second-width-title {
  display: block;
  width: 395px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.selected-row {
  background-color: lightblue;
  /* Adjust the color as needed */
}

.common-thead-second-width-title-answer {
  display: block;
  width: 340px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.common-thead-second-width-title-blog {
  display: block;
  width: 690px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.common-width-three-dot-text {
  display: block;
  width: 230px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.thead-second-width1 {
  text-align: center !important;
  width: 50% !important;
}

.errors-message-input input {
  border: 1px solid #ff3d57;
  border-radius: 5px;
}

.flatpickr-input {
  /* width: 300px; */
  width: 100%;
  height: 39px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  padding-left: 10px;
}

.flatpickr-input::placeholder {
  color: #6a6565f9;
  font-weight: 500;
  font-size: 14px;
}

/* -------------------Drop Zone */

/* Style the dropzone area */
.dropzone {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-height: 200px;
  /* Adjust the desired height */
}

.dropzone:hover {
  background-color: #eee;
}

/* Style the uploaded image */
.uploaded-image {
  max-width: 100%;
  max-height: 100%;
}

.main-image-box-user img {
  width: 40px !important;
  height: 40px !important;
}

.main-login-text-input input {
  height: 25px;
  font-size: 16px;
}

.main-login-text-input button {
  height: 45px !important;
  font-size: 17px;
  font-weight: 500;
  background-color: #405198;
  /* background-color: #222a44e7; */
}

.main-login-text-input .css-ulcmy6-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #344589f4;
}

.main-login-text-input input::placeholder {
  font-size: 16px;
}

.main-search-bar-select .css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.main-search-bar-select .css-13cymwt-control {
  background: white;
}

.change-password-component fieldset {
  border: 1px solid #a5a4b0 !important;
  color: #a5a4b0 !important;
}

.change-password-component label {
  color: #a5a4b0 !important;
}

/* 
.main-buttons-handle .css-1btqthh-MuiButtonBase-root-MuiButton-root:focus {
    background-color: #1976d2 !important;
    color: white !important;
} */

.main-footer-table-pagination .css-pdct74-MuiTablePagination-selectLabel {
  text-transform: capitalize !important;
}

.css-1ra94hr-MuiButtonBase-root-MuiFab-root {
  bottom: 118px !important;
}

.main-context-box {
  padding: 18px 0px 18px 15px !important;
}

.main-buttons-handle-order {
  display: grid;
  grid-template-columns: 1fr auto 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
}

.main-buttons-handle-order-return {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  width: 50%;
}

.main-order-master-page .css-11k801h .breadcrumb {
  margin-bottom: 20px !important;
}

.thead-width-zero {
  width: 0 !important;
}

.main-active-button-menu:hover {
  background-color: transparent !important;
}

.user-name-div {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  cursor: pointer;
}

.user-name-div:hover {
  background-color: transparent !important;
}

.user-name-div .first-name-span {
  font-size: 15px;
  font-weight: 500;
  color: #222a44;
}

.user-name-div .user-role-span {
  font-size: 15px;
  font-weight: 400;
  color: #4e609ada;
}

.dashboard-customer-span {
  font-size: 15px;
  font-weight: 500;
  color: #3b3b3b;
  letter-spacing: 0.7px;
}

/* .main-order-details-table .MuiTableRow-head .makeStyles-tableHeader-37 {
    padding-left: 0px !important;
} */

.main-billing-address-div .makeStyles-billing-63 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.product-th-tag {
  padding-left: 20px;
}

.model-footer {
  border-top: 1px solid #8080802c !important;
  padding-top: 18px !important;
}

.main-icon-details-button button {
  padding: 0 !important;
}

.pdf-exclude {
  display: none !important;
}

/* .pricing-main-div .makeStyles-billing-3 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
} */

/* .text-modal-div {
    border: 1px solid #00000047;
    padding: 8px;
    margin: 0;
    overflow: auto;
} */

/* 
.text-modal-div textarea {
    resize: none;
} */

@media only screen and (max-width: 1800px) {
  .thead-second-width-discount {
    width: 100px !important;
  }
}

.content {
  display: flex;
  justify-content: space-between;
}

/* Left Side:Start */
.left {
  display: block;
  height: fit-content;
  min-width: 250px;
  width: 35%;
  height: calc(100vh - 200px) !important;
  overflow-y: scroll;
  padding-top: 20px;
}

.left::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.left::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 0;
}

.left .product_img {
  height: 250px;
  width: 250px;
  margin: 10px;
  object-fit: cover;
}

.product_details {
  text-align: left;
  margin: 0 10px 10px;
  padding: 5px;
}

.product_details .title {
  font-size: 1.3em;
  color: rgb(99, 99, 99);
  font-weight: 900;
}

.product_details .discription {
  margin-top: 2px;
  font-size: 14px;
  color: gray;
}

.product_details .price {
  margin-top: 4px;
  font-size: 1.1em;
  font-weight: 600;
  white-space: pre;
}

.product_details .price span {
  font-weight: 500;
}

.product_details .price .price_original {
  text-decoration: line-through;
  font-size: 0.7em;
  font-weight: 400;
  color: gray;
}

.product_details .price .offer {
  color: #03ac13;
  font-weight: 600;
}

.product_details .other {
  font-size: 14px;
  color: grey;
}

/* Left Side:End */

/* Right Side:Start */
.right {
  display: block;
  width: 65%;
  height: calc(100vh - 200px) !important;
  overflow-y: scroll;
  margin: 31px 10px;

}

.right .product_description {
  text-align: left;
  /* margin: 30px 20px; */
}

.right::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.right::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 0;
}

.product_description h4 {
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 5px;
  margin-top: 0;
}

.product_description p {
  font-size: 14px;
  color: grey;
  margin-bottom: 15px;
}

.product_description .highlight {
  color: rgb(99, 99, 99);
  font-weight: 900;
}

.product_description .special {
  font-size: 0.85em;
}

.box {
  padding: 0px 25px 25px 25px;
  position: relative;
}

/* Right Side:End */

/* Responsiveness:Start */
@media screen and (min-width: 320px) and (max-width: 480px) {
  .content {
    display: flex;
    flex-direction: column;
    width: 100vw;
    border-radius: 0px;
  }

  section {
    width: 100% !important;
  }

  .product_img {
    width: calc(100vw - 6%) !important;
    height: 350px !important;
  }

  h4.title {
    font-size: 1em !important;
  }

  p {
    width: 90%;
  }

  p.discription {
    font-size: 0.7em !important;
  }

  p.other {
    font-size: 0.7em !important;
  }
}

/* Responsiveness:End */

/* attribute card in css */
.card-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.card_product .MuiPaper-root {
  box-shadow: 0px 0 0 0 rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 6%),
    0px 1px 8px 0px rgb(0 0 0 / 6%), 0px 1px 8px 0px rgb(0 0 0 / 6%) !important;
}

.card_product .css-m6cb7p-MuiTableCell-root {
  font-size: 12px !important;
}

.card_product .MuiCardHeader-root.css-185gdzj-MuiCardHeader-root {
  padding: 0px;
}

.card_product .css-46bh2p-MuiCardContent-root:last-child {
  padding-bottom: 2px;
}

.card_product .css-46bh2p-MuiCardContent-root {
  padding: 10px;
}

/* variant detail in css */
.card_variant {
  display: flex;
  flex-wrap: wrap;
}

.badge {
  line-height: 0.95;
}

.bg-success-subtle {
  background-color: var(--vz-success-bg-subtle) !important;
}

.text-success {
  --vz-text-opacity: 1;
  color: rgba(var(--vz-success-rgb), var(--vz-text-opacity)) !important;
}

.product-details-container {
  display: flex;
  align-items: center;
}

.detail {
  text-align: center;
  margin-right: 20px;
}

.detail2 {
  text-align: center;
  margin-left: 20px;
}

.divider {
  border-left: 1px solid #ccc;
  height: 60px;
  margin: 0 20px 20px;
}

.left_product {
  display: block;
  /* height: fit-content; */
  min-width: 250px;
  width: 60%;
  height: calc(100vh - 400px) !important;
  overflow-y: scroll;
  padding-top: 20px;
}

.left_product::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.left_product::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 0;
}

.left_product .product_img {
  height: 250px;
  width: 250px;
  margin: 10px;
  object-fit: cover;
}

.right_product {
  display: block;
  margin-left: 10px;
  width: 65%;
  height: calc(100vh - 400px) !important;
  overflow-y: scroll;
}

.right_product .product_description {
  text-align: left;
  margin: 30px 20px;
}

.right_product::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.right_product::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 0;
}

.right_product .all_product_img {
  height: 270px;
  width: 270px;
  margin: 10px;
  object-fit: cover;
  margin-top: 19px;
}

.badgeSuccess {
  background-color: #d5f2ee;
  color: #09af98;
  padding: 5px 17px;
  font-size: 14px;
  font-weight: 500;
  width: max-content;
  text-transform: uppercase;
}

.badgeFail {
  background-color: #fde8e4;
  color: #e74c3c;
  padding: 2px 15px;
  padding: 5px 17px;
  font-size: 14px;
  font-weight: 500;
  width: max-content;
  text-transform: uppercase;
}

.custom-swal-container {
  z-index: 9999 !important;
  /* Adjust the z-index value as needed */
}

.tab .tab_master {
  align-items: start !important;
}


.tab .css-cn1oe3 {
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) !important;
  margin: 42px 43px 33px 40px !important;
  padding-top: 0px !important;
  height: 100%;
}

.menu {
  border: 1px solid #b3b3bcf3 !important;
  padding: 0px 15px;
  background-color: #f3f3f9 !important;
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-image-display {
  width: 95px;
  height: 45px;
  object-fit: cover;
  border-radius: 5px;
}

/* Define the styles for the selected row */
.selected-row {
  background-color: #f0f0f0;
  /* Add any other styles you want for the entire row */
}

/* Style for table cell content */
.selected-row span,
.selected-row div {
  /* Add specific styles for spans and divs within the selected row */
  /* For example: */
  color: #333;
  font-weight: bold;
}

.main-input-padding-div {
  width: 50px;
}

/* Add this style to your CSS */
.hidden-checkbox {
  display: none;
}

.textInput-error {
  border: 1px solid #ff3d57;
}

.textInput-error::placeholder {
  color: #ff3d57;
}

.tippy-box {
  background-color: #dddbdb !important;
  color: black !important;
}

/* Add this style to hide the entire table cell when is_read is true */
.hide-cell {
  display: none;
}

.contact_checkBox .common-table-row thead tr th:first-child {
  text-transform: none !important;
  padding-left: 20px !important;
  width: 75px;
}

.item-thumb {
  width: 200px;
  height: 200px;
}

.item-thumb img {
  width: 200px;
  height: 180px;
  border: 1px solid #cbcbcb;
}

.item-title {
  text-align: center;
  margin-bottom: 10px;
  font-size: 19px;
  color: #C29958;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.price .total_price {
  text-align: center;
  color: black;
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #0c3258;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 300px;
  background-color: #115293;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  color: #fff;
  font: 16px/1;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  text-transform: uppercase;
  text-align: center;
  padding-right: 20px;
}

.ribbon-top-right {
  top: -10px;
  right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 71px;
}

.ribbon-top-right::after {
  bottom: 71px;
  right: 0;
}

.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

.tippy-content {
  background-color: #f1f1f1;
  box-shadow: 0px 0 0 0 rgb(0 0 0 / 8%), 0px 3px 4px 0px rgb(0 0 0 / 8%),
    0px 1px 8px 0px rgb(0 0 0 / 8%), 0px 1px 8px 0px rgb(0 0 0 / 8%) !important;
}

.pulse-base {
  background-color: white;
  font-size: 25px;
  height: 30px;
  width: 30px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d9bba1 !important;
  transition: all 400ms ease-in-out;
  position: relative;
  /* border: 15px solid #222; */
  border-radius: 50%;
  margin: 0 auto;
}

.pulse-circle {
  background: black;
}

@keyframes pulsate {
  0% {
    transform: scale(1.0, 1.0);
    opacity: 0.0;
  }

  50% {
    opacity: 1.2;
  }

  100% {
    transform: scale(1.3, 1.3);
    opacity: 0.0;
  }
}

.pulse-base:before {
  content: "";
  border: 10px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  height: 60px;
  width: 60px;
  position: absolute;
  left: -15px;
  bottom: -15px;
  animation: pulsate 1.6s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.0;
  z-index: 99;
}

.custom-tippy {
  transition: all 400ms ease-in-out;
  padding: 10px;
  border-radius: 50%;
  border: 1px solid transparent;
}

.custom-tippy:hover {
  padding: 10px;
  border: 1px solid white;
  border-radius: 50%;
}

.custom-tippy:hover .pulse-base {
  background-color: black;
}

#tippy-1 {
  margin: 20px !important;
}

.inline-height-1 {
  line-height: 0.9;
}

.model-title {
  background: #424d7916 !important;
  border-bottom: 1px solid #8080803b !important;
  color: #222a44 !important;
  font-size: 20px !important;
  margin-bottom: 20px !important;
  padding: 14px 0 12px 30px !important;
}

.product-title {
  line-height: 18px;
}

.product-title .product-content {
  margin-left: 10px;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.makeStyles-noUnderline-22.product-th-tag.css-m6cb7p-MuiTableCell-root {
  padding-left: 20px;
}

.product-span {
  font-weight: 500;
  margin-bottom: 10px
}

.order_price {
  margin-left: 50px;
}

.position-relative {
  position: relative;
}

.align-center {
  align-items: center;
}

.background-color {
  background-color: #80808029;
  padding: 12px;
}

.active-product {
  background: rgb(241, 238, 238);
  color: black;
}

.active-pin {
  background: green;
  color: #fff !important;
}

.pin_box {
  box-shadow: 0px 0 0 0 rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 6%),
    0px 1px 8px 0px rgb(0 0 0 / 6%), 0px 1px 8px 0px rgb(0 0 0 / 6%) !important;
  margin-left: 20px;
  width: 330px !important;
  cursor: pointer;
}


.video_url {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.image_return {
  height: 250px;
  width: 250px;
  cursor: pointer;
}

/* styles.css */

.MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root {
  z-index: 1;
}

.css-in6a7o-MuiPaper-root-MuiDrawer-paper {
  overflow-y: inherit;
}

@media (max-width: 1440px) {
  .thead-second-width-title-res {
    display: block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .thead-second-width-action-35-res {
    width: 45px !important;
  }

  .product-name-responsive {
    line-height: 20px;
  }

  .thead-second-width-title-responsive {
    width: 380px !important;
  }

  .thead-second-width-title-option-responsive {
    width: 585px !important;
  }

  .thead-second-width-stock-no-res {
    width: 100px !important;
  }

  .res-title {
    width: 80%;
  }

  .thead-second-width-title-price-78_res {
    width: 92px !important;
  }

  .thead-second-width-stone_res {
    width: 215px !important;
  }

  .pin_box {
    margin-left: 0px !important;
  }

  .p_color {
    padding: 5px 41px !important;
  }

  .success_res {
    margin: 0px 20px !important;
  }

  .order-actions {
    width: 64px !important;
  }

  .grid_top_customer {
    display: grid !important;
    grid-template-columns: 1fr !important;
    gap: 20px;
    margin-bottom: 25px;
  }

  .Pending_shipments {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr !important;
    gap: 20px;
    margin-bottom: 25px;
  }

  .carat_res {
    width: 74px !important;
  }

}

/* 768 responsive  */
@media (min-width:768px) and (max-width:991px) {
  .Pending_shipments {
    display: grid !important;
    grid-template-columns: 1.2fr 1.5fr 1.6fr !important;
    margin-bottom: 25px;
    gap: 0px;
  }

  .badgeSuccess {
    background-color: #d5f2ee;
    color: #09af98;
    padding: 5px 17px;
    font-size: 14px;
    font-weight: 500;
    width: max-content;
    text-transform: uppercase;
  }

  .badgeFail {
    background-color: #fde8e4;
    color: #e74c3c;
    padding: 2px 15px;
    padding: 5px 17px;
    font-size: 14px;
    font-weight: 500;
    width: max-content;
    text-transform: uppercase;
  }

  .chart-container {
    display: grid !important;
    grid-template-columns: 1fr !important;
    gap: 20px,
  }

  .profit_chart {
    display: grid !important;
    grid-template-columns: 100% !important;
    gap: 20px,
  }
}

/* .product-details-select-box .css-c3xndr-MuiPaper-root-MuiDialog-paper {
  overflow-y: inherit !important;
  z-index: 1 !important;
}

.product-details-select-box .css-ypiqx9-MuiDialogContent-root {
  overflow-y: inherit !important;
} */
.product-details-select-box .MuiPaper-root {
  overflow-y: inherit !important;
  z-index: 1 !important;
}

.product-details-select-box .MuiDialogContent-root {
  overflow-y: inherit !important;
}

.react-select-group {
  overflow-y: inherit !important;
}

.search-modal-header .css-1xc3v61-indicatorContainer,
.search-modal-header .css-15lsz6c-indicatorContainer {
  color: white !important;
}

/* .background_color .common-table-row tbody tr td button:hover {
  background-color: rgb(17, 82, 147) !important;
} */
.common-table-row tbody tr td .back_color:hover {
  background-color: rgb(17, 82, 147) !important;
}

.MuiDialogContent-root,
.css-1ty026z {
  z-index: 1 !important;
}

.checkBox-padding-remove {
  padding: 0px !important;
}

/* .abc .ps__rail-y {
 top: 0px !important;
 height: 0px !important;
 right: 0px !important;
} */

/* scroll remove in a navigation */
.scroll-remove .ps--active-x>.ps__rail-x,
.scroll-remove .ps--active-y>.ps__rail-y {
  display: none !important;
}

.user-avatar div:hover {
  background-color: rgba(0, 0, 0, 0.09) !important;
}

.user-avatar:hover {
  background-color: rgba(0, 0, 0, 0.09) !important;
}

.permission_color {
  background-color: #E0E2E8 !important;
  padding-left: 10px !important;
  font-size: 14px !important;
}

.tax_heading {
  font-size: 16px;
  font-weight: 400;
  border: #95959575 1px dashed;
  padding: 0px 10px 0px 13px;
  margin-bottom: 10px;
  /* text-transform: uppercase; */
  /* letter-spacing: 1px; */
}

.d-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.d-flex2 {
  display: flex;
  justify-content: flex-start;
}

.tax-select-design .css-1fdsijx-ValueContainer:focus-visible {
  outline: none !important;
}

.tax-select-design .css-13cymwt-control,
.tax-select-design .css-t3ipsp-control {
  border-bottom: gray 1px solid !important;
  border-radius: 0px !important;
  border-width: 0px !important;
  border-bottom-width: 1px !important;
  box-shadow: none !important;
}

.tax-select-design .css-t3ipsp-control {
  box-shadow: none !important;
}

.tax-select-design .css-t3ipsp-control:focus-visible,
.tax-select-design .css-t3ipsp-control:focus {
  border-color: transparent !important;
}

.header-profile-user {
  height: 32px;
  width: 32px;
}

.rounded-circle {
  border-radius: 50% !important;
}

.tax_first_row .common-table-row tbody tr td:first-child {
  text-transform: none !important;
  padding-left: 20px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.sub-category-tooltip {
  bottom: 55px !important;
}

.sub-category-tooltip-shape {
  bottom: 78px !important;

}